.container {
  min-height: 100vh;

  .ant-layout-footer {
    padding: 0;
    position: relative;
    bottom: 0;
    width: max-content;
    left: 50%;
    transform: translate(-50%, 0);
    margin-bottom: 1em;
    margin-top: 1em;
  }
}
.upload-element .ant-upload-list {
  overflow: auto;
}
.template-container {
  overflow: hidden;
}
.ant-steps {
  padding-bottom: 20px;
}

.ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
  background-color: transparent;
}
.ant-alert-info {
  border: none;
  border-bottom: 1px solid #ffd49c;
}