@import "../../common/styles/_colors.scss";

.text {
  display: inline;
  margin: 0 !important;
  padding: 0 !important;
}

.error-text {
  height: 1ch;
  color: $error;
}
