@import "../../common/styles/_colors.scss";

h3 {
  height: "160px";
  line-height: "160px";
  text-align: "center";
  background: "#364d79";
}
.img-container {
  width: 100%;
  height: 20vh;
}
.landing-container {
  display: flex;
  flex-direction: column;
  background-color: rgba($color: rgb(255, 255, 255), $alpha: 0.6);
  min-height: 100%;
  padding: 20px;
  min-width: calc(230px * 5);

  .landing-title {
    text-align: left;
    font-size: xx-large;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-color: black;
    padding-bottom: 0.25rem;
  }

  .buttons-section {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    padding: 30px 0px;
    // margin: 0px 20px;
    min-width: calc(230px * 5);
    height: 100%;
    gap: 10px !important;

    .card {
      background-color: #ffffff;
      max-width: 200px;
      height: 300px;
      overflow: hidden;
      border-radius: 10px;
      position: relative;
      padding: 20px;
      cursor: pointer;

      border-width: 5px;
      border-style: solid;
      border-color: $secondary-color;

      transition: all 100ms ease-in-out;

      .card-title {
        font-weight: 600;
        font-size: 1.3rem;
        z-index: 2;
        position: relative;
        color: #373737;
      }

      .card-desc {
        display: block;
        display: -webkit-box;
        height: 170px;
        -webkit-line-clamp: 8;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 0.85rem;
        transition: all 100ms ease-in-out;
      }

      .card-icon {
        margin-right: 0;
        margin-left: 0;
        display: block;
        svg {
          width: 20px;
          height: 20px;
          color: #373737;
        }
      }
    }

    .card:hover {
      transform: scale3d(1.075, 1.075, 1.075);
      box-shadow: 0px 0px 21px 0px $secondary-color;
      -webkit-box-shadow: 0px 0px 21px 0px $secondary-color;
      -moz-box-shadow: 0px 0px 21px 0px $secondary-color;
      .card-desc {
        // -webkit-line-clamp: unset;
        // height: max-content;
      }
    }
  }
}
