@import "../../common/styles/_colors.scss";

.header {
  background-color: #fff;
  border-color: $divider-gray;
  border-style: solid;
  border-width: 0px 0px 1px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .logo {
    cursor: pointer;;
  }

  img {
    width: 32px;
    height: 32px;
    margin-right: 10px;
  }
  h4 {
    flex-grow: 1;
  }
  .avatar {
    margin-right: 5px;
  }
  .logout-button {
    padding: 5px;
    margin-left: 10px;
    border-radius: 10%;
    transition: 250ms background ease;
    margin-top: 0.2em;
    svg {
      height: 1.25em;
      width: 1.25em;
      transition: 250ms color ease;
    }
  }
  .logout-button:hover {
    background-color: $lite-primary-color;
    svg {
      color: $primary-color;
    }
  }
}
