.upload-element {
  .ant-upload-list {
    // max-width: 12rem;
    max-height: 300px;
    overflow: scroll;
  }
}

.dropdown-button {
  transform: translateX(-2px);
}
