@import "../../common/styles/_colors.scss";

.login-background {
  background: #c9d6ff; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #e2e2e2,
    #c9d6ff
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #e2e2e2,
    #c9d6ff
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .login-container {
    background-color: #fcfcfc;
    width: 500px;
    max-height: max-content;
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 10px;
    // align-items: center;
    justify-content: center;
    -webkit-box-shadow: 0px 0px 5px $divider-gray;
    box-shadow: 0px 0px 5px $divider-gray;
    position: relative;

    .title {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 10px;
      margin-bottom: 20px;
      img {
        margin-right: 30px;
        width: 64px;
        height: 64px;
      }
    }

    .info-button {
      span {
        margin-left: 5px;
      }
    }

    .ant-space-vertical {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 16px !important;
      align-items: center;
      padding: 10px;
      .ant-space-item {
        width: 80%;
        .login-form-button {
          width: 100%;
        }
        .end {
          display: flex;
          flex-direction: row-reverse;
        }
      }
    }
    .login-form-forgot {
      float: right;
    }
  }
}
