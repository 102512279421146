@import "../../common/styles/_colors.scss";

.error-field {
  border-color: $error-lite;
  border-right-width: 1 px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgb(255 77 79 / 20%);
}

.error-field:hover {
  background-color: #fff;
  border-color: $error !important;
}
