@import "../../common/styles/_colors.scss";

.steps-container {
  padding: 2em;
  padding-bottom: 4em;
  min-height: 50vh;
  position: relative;
  margin-bottom: 1em;
  background-color: #fffa;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 5px $divider-gray;
  box-shadow: 0px 0px 5px $divider-gray;

  .ant-steps-item-finish .ant-steps-item-icon {
    background-color: $success-green;
    border-color: $success-green;
  }
  .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: #fff;
  }
  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after {
    background-color: $success-green;
  }

  .steps-content {
    margin-top: 1.5em;
    .template-container {
      background-color: transparent;
      min-height: auto;
    }
  }
  .steps-action {
    width: 100%;
    position: absolute;
    bottom: 0;
    margin-bottom: 20px;
  }
}

[data-theme='compact'] .site-navigation-steps,
.site-navigation-steps {
  margin-bottom: 30px;
  box-shadow: 0px -1px 0 0 #e8e8e8 inset;
}