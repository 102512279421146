@import "../../common/styles/_colors.scss";

.template-container {
  background-color: rgba(250, 250, 250);
  min-height: 85.5vh;
  overflow: hidden;
  padding: 1rem;
  .title-container {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-color: black;
    padding-bottom: 0.25rem;
    .take-a-tour-button{
       margin-left: 20px;
	  }
  }
}
