@import "~antd/dist/antd.less";

// Helper Classes
.flex {
  display: flex;
}

.flex--center {
  align-items: center;
  justify-content: center;
}

.flex--row {
  flex-direction: row;
}

.flex--row-reverse {
  flex-direction: row-reverse;
}

.flex--column {
  flex-direction: column;
}

.flex--center-reverse {
  flex-direction: column-reverse;
}

.flex--grow {
  flex-grow: 1;
}

.max-height {
  min-height: 100%;
}

.gap-0-imp {
  gap: 0 !important;
}

.gap--bottom {
  margin-bottom: 20px;
}

.gap--bottom-x2 {
  margin-bottom: 40px;
}

.gap--right {
  margin-right: 20px;
}

.gap--left {
  margin-left: 20px;
}

.gap--top {
  margin-top: 20px;
}

.m-0 {
  margin: 0 !important;
}

.p-0 {
  padding: 0 !important;
}

.h-auto {
  height: auto !important;
  min-height: auto !important;
}

@primary-color: #f5821f;